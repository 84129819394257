import {inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CourseLauncherJobResult, UserAttributes} from '@sandler/lms';

export class SeertechService {
    constructor(public url: string) {}

    private _http = inject(HttpClient);

    updateUser(userAttributes: UserAttributes) {
        return this._http.post<CourseLauncherJobResult>(new URL('/api/build-url-for-user', this.url).toString(), userAttributes);
    }
}
