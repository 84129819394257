import {Component, Signal, signal} from '@angular/core';
import { RouterModule } from '@angular/router';
import {AssessmentUpdaterComponent} from './assessment-updater/assessment-updater.component';
import {SeertechService} from './seertech.service';
import {HttpClientModule} from '@angular/common/http';
import {UserAttributes} from '@sandler/lms';
import {CommonModule} from '@angular/common';
import {AngularEnvironment} from '@sporesoftware/angular-processing';
import {BannerComponent} from './banner/banner.component';

@Component({
    standalone: true,
    imports: [BannerComponent, AssessmentUpdaterComponent, RouterModule, HttpClientModule, CommonModule],
    providers: [
        { provide: SeertechService, useFactory: (environment: AngularEnvironment) => new SeertechService(environment.configuration['seertech'].url), deps: [AngularEnvironment] }
    ],
    selector: 'sandler-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'seertech-course-launcher-web';

    constructor() {
        const params = new URLSearchParams(window.location.search);
        const username = params.get('username');
        const userAttributes = username ? {
                username,
                firstname: params.get('firstname') || undefined,
                lastname: params.get('lastname') || undefined,
                email: params.get('email') || undefined,
                company: params.get('company') || undefined
            } : undefined;
        this.userAttributes = signal(userAttributes);
    }

    userAttributes: Signal<UserAttributes | undefined>;
}
