import {Component, Injector, Input, Signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CourseLauncherJobResult, CourseLauncherJobStatus, UserAttributes} from '@sandler/lms';
import {AssessmentIntroComponent} from '../assessment-intro/assessment-intro.component';
import {SeertechService} from '../seertech.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
    selector: 'sandler-assessment-updater',
    standalone: true,
    imports: [CommonModule, AssessmentIntroComponent, MatProgressSpinnerModule],
    templateUrl: './assessment-updater.component.html',
    styleUrls: ['./assessment-updater.component.scss'],
})
export class AssessmentUpdaterComponent {
    constructor(private _seertech: SeertechService, private _injector: Injector) {}

    private _userAttributes!: UserAttributes;
    get userAttributes() { return this._userAttributes; }
    @Input({required: true}) set userAttributes(value: UserAttributes) {
        this._userAttributes = value;
        if (this.updateResult as any)
            throw new Error('Cannot set user attributes more than once.');
        this.updateResult = toSignal(this._seertech.updateUser(value), {initialValue: { status: CourseLauncherJobStatus.Pending }, injector: this._injector});
    }

    updateResult!: Signal<CourseLauncherJobResult>;
    protected readonly JobStatus = CourseLauncherJobStatus;
}
